<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"

	export default {
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Salary Sheet",
						active: true,
					},
				],
				corporateId: '',
				selectedBranch: '',
				employeeFilterBranch: [],
				isReportTableBusy: false,
				excelDownloading: false,
				tableData: [],
				totalRows: 0,
				totalPages: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "Date",
				moment: this.$moment,
				sortDesc: false,
				searchQuery: '',
				fields: [
					{
						key: "Action",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "employeeName",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "designation",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "present",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "paidLeaves",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "unpaidLeaves",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "totalDays",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "overtimeHours",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "grossSalary",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "overtime",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "reimbursements",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "bonus",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						label: 'PF',
						key: "pf",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						label: 'ESIC',
						key: "esic",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						label: 'TDS',
						key: "tds",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "netSalary",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
			};
		},
		computed: {
			rows() {
				return this.totalRows;
			},
		},
		mounted() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getBranchList()
		},
		methods: {
			onFiltered: function(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length;
				this.currentPage = 1;
			},
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.employeeFilterBranch = result.data.data;
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getSalarySheet()
				}).catch(error => {
					console.log(error)
				})
			},
			getSalarySheet: function() {
				this.isReportTableBusy = true;
				//this.tableData = [];
				this.axios.post("admin/employee-salary-list", {
					branchId: this.selectedBranch._id,
					page: this.currentPage,
					pageSize: this.perPage,
					searchKeyword: this.searchQuery,
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then((response) => {
					this.isReportTableBusy = false
					this.tableData = response.data.data.listing
					this.totalRows = response.data.data.totalCounts
					this.totalPages = response.data.data.totalPageNumbers
				}).catch((error) => {
					console.log(error)
				});
			},
			clearFilter: function() {
				this.selectedBranch = this.employeeFilterBranch[0]
				this.getSalarySheet()
			},
			applyFilter: function() {
				this.getSalarySheet()
			},
			downloadSample: function() {
				this.excelDownloading = true;
				this.axios.post("exportExcel", {
					exportData: this.tableData,
					page: "SalarySheet",
				}, {
					responseType: "blob",
				}).then((response) => {
					this.excelDownloading = false
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download", "employee-report.xlsx")
					document.body.appendChild(link)
					link.click()
				})
			},
			changePage: function(value) {
				this.currentPage = value
				this.getSalarySheet()
			},
			changePageSize: function(value) {
				this.perPage = value
				this.getSalarySheet()
			},
			searchFilter: function(value) {
				this.searchQuery = value
				this.getSalarySheet()
			},
			downloadReport: function() {
				let month = new Date().getMonth()
				let year = new Date().getFullYear()
				this.excelDownloading = true
				this.axios.post('admin/download-salary-sheet', {
					corporateBranchId: this.selectedBranch._id,
					month: month,
					year: year,
				}, {
					responseType: "blob",
				}).then((result) => {
					this.excelDownloading = false
					//console.log(result.data)
					const url = URL.createObjectURL(
						new Blob([result.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "salary-sheet.xlsx");
					document.body.appendChild(link);
					link.click();
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	};
</script>
<style scoped></style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="card" style="margin-top: -27px">
			<div class="card-body">
				<div class="row inner mb-2">
					<div class="col-md-4">
						<label for="branch">Select Branch</label>
						<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						</multiselect>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<div role="group" class="btn-group">
									<button type="button"
										class="btn btn-outline-primary" @click="downloadReport()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										Show &nbsp;&nbsp;
										<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage"
											size="sm" :options="pageOptions" @change="changePageSize"></b-form-select>&nbsp;&nbsp;&nbsp;
										entries
									</label>
								</div>
							</div>
							<!-- Search -->
							<div class="col-sm-12 col-md-4 row">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">
										Search:
										<b-form-input v-model="filter" type="search" placeholder="Search..."
											class="form-control form-control-sm ms-2" @keyup="searchFilter(filter)"></b-form-input>
									</label>
								</div>
							</div>
							<!-- End search -->
						</div>
						<div class="table-responsive mb-0">
							<b-table
								id="holiday-list"
								striped
								hover
								outlined
								ordered
								:items="tableData"
								:fields="fields"
								thead-class="bg-transparent"
								responsive="sm"
								:per-page="0"
								:current-page="currentPage"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								fixed-header
								:busy="isReportTableBusy"
								show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(date)="data">
									<div>{{ moment(data.item.date).format('MMMM DD, YYYY') }}</div>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<!-- pagination -->
										<b-pagination
											aria-controls="holiday-list"
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>